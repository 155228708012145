<template>
    <div class="row">
        <div class="col-md-8">
            <i class="far fa-address-card mr-1"></i> {{ $t('general.nombre') }}
            <p class="">
                <strong>{{perito.nombre}}</strong>
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4" v-if="perito.telefono1">
            <i class="fas fa-phone-alt"></i> {{ $t('general.telefono') }}
            <p class="">
                <button type="button" v-on:click='llamar(perito.telefono1)'
                    class="btn btn-block btn-outline-info btn-sm">{{perito.telefono1}}</button>
            </p>
        </div>
        <div class="col-md-4" v-if="perito.movil1">
            <i class="fas fa-phone-alt"></i> {{ $t('general.movil') }}
            <p class="">
                <button type="button" v-on:click='llamar(perito.movil1)'
                    class="btn btn-block btn-outline-info btn-sm">{{perito.movil1}}</button>
            </p>
        </div>
        <div class="col-md-4" v-if="perito.email">
            <i class="fas fa-mail-bulk"></i> Email
            <p class="">
                <button type="button" @click="enviaremail(perito.email)"
                    class="btn btn-block btn-outline-info btn-sm">{{perito.email}}</button>
            </p>
        </div>
    </div>
    <div class="row">
        <div class="row">
            <div class="col-md-12">
                <strong> {{ $t('general.archivosdelperito') }}</strong>
                <div class="row" v-if="servicio.documentos">
                    <div class="col-md-4" v-for="archivos in servicio.documentos.perito" :key="archivos.id_documento">
                        <Image
                            v-if="archivos.documento.substr(archivos.documento.length - 3) == 'jpg' || archivos.documento.substr(archivos.documento.length - 3) == 'png' || archivos.documento.substr(archivos.documento.length - 4) == 'jpeg' || archivos.documento.substr(archivos.documento.length - 3) == 'bmp' || archivos.documento.substr(archivos.documento.length - 3) == 'gif' || archivos.documento.substr(archivos.documento.length - 4) == 'tiff' || archivos.documento.substr(archivos.documento.length - 4) == 'webp'"
                            :src='archivos.url_completa_descarga' :id="archivos.id_documento" width="50"
                            :title="archivos.documento" preview :alt="archivos.documento" />
                        <a v-else :href='archivos.url_completa_descarga' :title="archivos.documento"
                            :alt="archivos.documento">
                            <i class="fas fa-file" style="font-size:50px;"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>


</template>
<script>  
import Image from 'primevue/image';

export default ({
        props: ['id', 'servicio','perito'],

    setup() {     
    },
    components: {
        Image,
    },
    data() {
        return {
            urlglobal: '',

        }
    },
    methods: {
        llamar: function (telf) {
            window.open("tel:" + telf);
        },
        enviaremail(correo) {
            location.href = "mailto:" + correo;
        },
        cargarurl() {
            this.urlglobal = localStorage.getItem('direccion');
        },
    },
    mounted() {
        this.cargarurl();
    },
})


</script>