<template>
    <div class="row">
        <div class="col-md-4">
            <div class="form-group">
                <label>{{ $t('general.codigo') }}</label>
                <input v-model="siniestro.codigo" type="text" class="form-control input-sm" placeholder="Codigo">
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label>{{ $t('general.naviso') }}</label>
                <input v-model="siniestro.n_aviso" type="text" class="form-control input-sm" placeholder="Aviso">
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label>{{ $t('general.anyodelsiniestro') }}</label>
                <input v-model="siniestroaux.anno_servicio" type="text" class="form-control input-sm" placeholder="Codigo">
            </div>
        </div>
        <div class="col-md-12" v-if="empresas">
            <div class="form-group">
                <label>{{ $t('general.empresafactura') }}</label>
                <select v-model="selected_empresa" id="" class="form-control">
                    <option v-for="empresa in empresas" :key="empresa.id" :value="empresa">{{ empresa.nombre_empresa }}</option>
                </select>
            </div>
        </div>
        <div class="col-md-12">
            <div class="form-group">
                <label @click="console.log(servicio, listadocompanyias)">{{ $t('general.compania') }}</label>
                <select v-model="selected_companyia" class="form-control">
                    <option v-for="companyia in listadocompanyias" :key="companyia.id" :value="companyia.id">{{ companyia.nombre+' ('+companyia.prefijo+')' }} </option>
                </select>
            </div>
        </div>
        <div class="col-md-12" v-if="listadocorredurias">
            <div class="form-group">
                <label @click="console.log('corredurias',listadocorredurias)">{{ $t('general.companiacorreduria') }}</label>
                <select v-model="selected_correduria" class="form-control">
                    <option v-for="correduria in listadocorredurias" :key="correduria.id" :value="correduria.id">{{ correduria.nombre }}</option>
                </select>
            </div>
        </div>
        <div class="col-md-12" v-if="listadocompanyiasseguro">
            <div class="form-group">
                <label @click="console.log(siniestroaux)">{{ $t('general.contrato') }}</label>
                <v-select v-model="selected_companyia_seguros" :options="listadocompanyiasseguro"></v-select>
                <input v-model="companiaSegurosInput" type="text" class="form-control input-sm">
            </div>
        </div>
        <div class="col-md-12" v-if="listadocompanyiasseguro2">
            <div class="form-group">
                <label>{{ $t('general.companiadeseguros') }}</label>
                <select class="form-control" v-model="selected_companyia_seguros2">
                    <option v-for="companyia in listadocompanyiasseguro2" :key="companyia.id_companyia_asitur" :value="companyia.id_companyia_asitur">{{ companyia.nombre }}</option>
                </select>
            </div>
        </div>
        <div style="margin: 0;" v-if="pap" class="row">
            <div class="col-md-4">
                <div class="form-group">
                    <label for="idcompania">Id Compañía:</label>
                    <input v-model="idcompania" type="text" class="form-control input-sm">
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label for="idcompania">Id Ramo:</label>
                    <input v-model="idramo" type="text" class="form-control input-sm">
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label for="idcompania">Id Interviniente:</label>
                    <input v-model="idinterviniente" type="text" class="form-control input-sm">
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label>{{ $t('general.referencia') }}</label>
                <input v-model="siniestroaux.referencia_companyia_asitur_servicio" type="text" class="form-control input-sm">
            </div>
        </div>
        <div class="col-md-12">
            <div class="form-group">
                <label >{{ $t('general.danyos') }}</label>
                <textarea v-model="siniestroaux.daños" class="form-control"></textarea>
            </div>  
        </div>
        <div class="col-md-12">
            <div class="form-group">
                <label >{{ $t('general.notaimportante') }}</label>
                <textarea v-model="siniestroaux.nota_importante" class="form-control"></textarea>
            </div>  
        </div>
        <div class="col-md-12">
            <div class="form-group">
                <label >{{ $t('general.observaciones') }}</label>
                <textarea v-model="siniestroaux.observaciones" class="form-control"></textarea>
            </div>  
        </div>
        <!--<div class="col-md-12">
            <div class="form-group">
                <label >{{ $t('general.viptexto') }}</label>      
            </div>  
        </div>-->
    </div>            
</template>
<script>
import { PwgsApi } from '../../../../../services/PwgsApi';

export default ({
    props:['servicio','id','guardartoken', 'servicioext'],
    setup() {
        return;
    },
    data() {
        return {
            selected_companyia_seguros:'',
            selected_companyia_seguros2:'',
            selected_companyia: '',
            selected_correduria:'',
            selected_empresa: '',
            siniestro: '',
            companyias:'',
            listadocompanyiasseguro:[],
            listadocompanyiasseguro2:[],
            listadocompanyias: [],
            listadocorredurias:[],
            siniestroaux: [],
            listaempresas: [],
            empresas: [],
            pap:false,
            idcompania:'',
            idinterviniente:'',
            idramo:'',
            companiaSegurosInput:'',
            primero:true,
        }
    },
    methods: {
        async listadocompanias() {
            //get  modulos/pwgsapi/index.php/companias/simple
            const api = new PwgsApi;
            const datos = await api.get("companias/simple");
            this.companyias = datos.datos;
            this.listadocompanyias = datos.datos;            
        },
        async listadocompaniasseguros() {
            //get  modulos/pwgsapi/index.php/companias/simple
            const api = new PwgsApi;
            this.listadocompanyiasseguro = await api.get("companias/"+this.$props.servicio.compania.id+"/contratos-servicios");    
            var resp = await api.get("companias/"+this.$props.servicio.compania.id+"/companias-seguros");
            this.listadocompanyiasseguro2 = resp.datos;
        },
        async listadocompaniasseguroscambio(nuevo) {
            console.log('cambio', nuevo);
            //get  modulos/pwgsapi/index.php/companias/simple
            const api = new PwgsApi;
            this.listadocompanyiasseguro = await api.get("companias/"+nuevo+"/contratos-servicios");
            var resp = await api.get("companias/"+nuevo+"/companias-seguros");
            this.listadocompanyiasseguro2 = resp.datos;
            if(this.primero){
                this.primero = false;
            }else{
                this.selected_companyia_seguros2 = '';
                this.companiaSegurosInput = '';
                this.selected_companyia_seguros = '';
            }
        },
        async cargarlistadocorredurias() {
            //get  modulos/pwgsapi/index.php/companias/"+this.$props.servicio.compania.id+"/companias-correduria
            const api = new PwgsApi;
            const datos = await api.get("companias/"+this.$props.servicio.compania.id+"/companias-correduria");
            this.listadocorredurias = datos.datos;
        },
        async listadoempresas() {
            //GET  modulos/pwgsapi/index.php/empresas/simple
            const api = new PwgsApi;
            this.empresas = await api.get("empresas/todas");
            let idempresa = this.servicioext.datos.empresas_factura_id_empresa;
            console.log('idempresa',idempresa);
            this.selected_empresa = this.empresas.find(empresa => empresa.id == idempresa);
            console.log('selected_empresa', this.selected_empresa);
        },
        async cargarservicio() {
            //get  modulos/pwgsapi/index.php/servicios/:idq
            const api = new PwgsApi;
            const datos = await api.get("servicios/" + this.$props.id);
            this.siniestroaux = datos.datos;
            this.selected_companyia_seguros = this.siniestroaux.companyia_seguros_servicio;
            this.selected_companyia_seguros2 = this.siniestroaux.id_companyia_asitur;
            this.selected_correduria = this.siniestroaux.id_correduria;
        },
        async guardarsiniestro() { 
            //put  modulos/pwgsapi/index.php/servicios/:id/modificar-servicio
            // selected_companyia, siniestroaux.companyia_seguros_servicio ,
            try {
                const api = new PwgsApi;
                let subidadatos = {};
                if(this.pap){
                    subidadatos = {pap_interviniente:this.idinterviniente,pap_compania:this.idcompania,pap_ramo:this.idramo , 
                        urgente: this.siniestro.urgente, codigo: this.siniestro.codigo, n_aviso: this.siniestro.n_aviso, asitur_referencia: 
                        this.siniestroaux.referencia_companyia_asitur_servicio, pap_year: this.siniestroaux.anno_servicio, danos: this.siniestroaux.daños, 
                        observaciones: this.siniestroaux.observaciones, nota_importante: this.siniestroaux.nota_importante, id_empresa: this.selected_empresa.id ,
                        id_compania : this.selected_companyia, id_compania_seguro:this.companiaSegurosInput, asitur_compania:this.selected_companyia_seguros2, id_correduria: this.selected_correduria}
                }else{
                    subidadatos = { urgente: this.siniestro.urgente, codigo: this.siniestro.codigo, n_aviso: this.siniestro.n_aviso, 
                        asitur_referencia: this.siniestroaux.referencia_companyia_asitur_servicio, pap_year: this.siniestroaux.anno_servicio, 
                        danos: this.siniestroaux.daños, observaciones: this.siniestroaux.observaciones, nota_importante: this.siniestroaux.nota_importante, 
                        id_empresa: this.selected_empresa.id, id_compania : this.selected_companyia, id_compania_seguro:this.companiaSegurosInput, asitur_compania:this.selected_companyia_seguros2,
                        id_correduria: this.selected_correduria}
                }
                await api.put('servicios/' + this.$props.id + '/modificar-servicio', subidadatos);
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
            }
             catch (error) {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
            this.$parent.$parent.$parent.$parent.cargardatos(this.$props.id);

        },
        comprobarPap(){
            if(this.servicio.compania.sistema == 'P.A.P.'){
                this.pap = true;
                this.idcompania = this.servicioext.datos.compannia_servicio;
                this.idramo = this.servicioext.datos.ramo_servicio;
                this.idinterviniente = this.servicioext.datos.pap_interviniente;
            }
        }
    },
    mounted() {
        this.comprobarPap();
        this.cargarservicio();
        this.listadocompanias();
        this.listadoempresas();
        this.listadocompaniasseguros();
        this.cargarlistadocorredurias();
        this.siniestro = this.$props.servicio;
        this.selected_companyia = this.$props.servicio?.compania?.id;
    },
    watch: {
        selected_companyia_seguros(valor){
            this.companiaSegurosInput = valor;
        },
        selected_companyia(valor){
            this.listadocompaniasseguroscambio(valor);
        },
        id() {
            this.cargarservicio();
            this.listadocompanias();
            this.listadoempresas();
            this.listadocompaniasseguros();
            this.cargarlistadocorredurias();
            this.siniestro = this.$props.servicio;
            this.selected_companyia = this.$props.servicio?.compania?.id;
            this.selected_correduria = this.$props.servicio?.correduria?.id;
        },
        guardartoken() {
            this.guardarsiniestro();
            //this.cargarservicio();
            this.$parent.$parent.$parent.$parent.cargardatos(this.$props.id);
        },
    }
})
</script>