<template>
    <div class="row">
        <div class="col-md-4">
            <i class="far fa-address-card mr-1"></i> {{ $t('general.nombre') }}
            <p class="">
                <strong>{{perito.nombre}}</strong>
            </p>
        </div>
        <div class="col-md-4">
            <i class="far fa-address-card mr-1"></i> {{ $t('general.empresa') }}
            <p class="">
                <strong>{{ perito.empresa }}</strong>
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4" v-if="perito.telefono1">
            <i class="fas fa-phone-alt"></i> {{ $t('general.telefono') }} 1
            <p class="">
                <button type="button" v-on:click='llamar(perito.telefono1)'
                    class="btn btn-block btn-outline-info btn-sm">{{perito.telefono1}}</button>
            </p>
        </div>
        <div class="col-md-4" v-if="perito.telefono2">
            <i class="fas fa-phone-alt"></i> {{ $t('general.telefono') }} 2
            <p class="">
                <button type="button" v-on:click='llamar(perito.telefono2)'
                    class="btn btn-block btn-outline-info btn-sm">{{perito.telefono2}}</button>
            </p>
        </div>
        <div class="col-md-4" v-if="perito.email">
            <i class="fas fa-mail-bulk"></i> Email
            <p class="">
                <button type="button" @click="enviaremail(perito.email)" class="btn btn-block btn-outline-info btn-sm">{{ perito.email }}</button>
            </p>
        </div>
        <div class="col-md-4" v-if="perito.movil">
            <i class="fas fa-phone-alt"></i> {{ $t('general.movil') }} 1
            <p class="">
                <button type="button" v-on:click='llamar(perito.movil1)'
                    class="btn btn-block btn-outline-info btn-sm">{{perito.movil1}}</button>
            </p>
        </div>
        <div class="col-md-4" v-if="perito.movil2">
            <i class="fas fa-phone-alt"></i>{{ $t('general.movil') }} 2
            <p class="">
                <button type="button" v-on:click='llamar(perito.movil2)'
                    class="btn btn-block btn-outline-info btn-sm">{{ perito.movil2 }}</button>
            </p>
        </div>
        <div class="col-md-4" v-if="perito.fax">
            <i class="fas fa-fax"></i> {{ $t('general.fax') }}
            <p class="">
                <button type="button" class="btn btn-block btn-outline-info btn-sm">{{ perito.fax }}</button>
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <strong> {{ $t('general.informedelperito') }}</strong>
            <p>{{ servicioext.datos.informe_perito }}</p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <strong> {{ $t('general.archivosdelperito') }}</strong>
            <div class="row">
                <div class="col-md-4" v-for="archivos in servicio.documentos.perito" :key="archivos.id_documento">
                    <Image
                        v-if="archivos.documento.substr(archivos.documento.length - 3) == 'jpg' || archivos.documento.substr(archivos.documento.length - 3) == 'png' || archivos.documento.substr(archivos.documento.length - 4) == 'jpeg' || archivos.documento.substr(archivos.documento.length - 3) == 'bmp' || archivos.documento.substr(archivos.documento.length - 3) == 'gif' || archivos.documento.substr(archivos.documento.length - 4) == 'tiff' || archivos.documento.substr(archivos.documento.length - 4) == 'webp' "
                        :src='archivos.url_completa_descarga'
                        :id="archivos.id_documento" width="50" :title="archivos.documento" preview
                        :alt="archivos.documento" />
                    <a v-else
                        :href='archivos.url_completa_descarga'
                        :title="archivos.documento" :alt="archivos.documento">
                        <i class="fas fa-file" style="font-size:50px;"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Image from 'primevue/image';
export default ({
    props: ['id', 'servicio', 'perito','servicioext'],
    setup() {
    },
    components: {
        Image,
    },
    data() {
        return {
            urlglobal:'',
        }
    },
    methods: {
        llamar: function (telf) {
            window.open("tel:"+telf);
        },
        cargarurl() {
            this.urlglobal = localStorage.getItem('direccion');
        },
        enviaremail(correo) {
            location.href = "mailto:" + correo;
        }

    },
    mounted() {
        this.cargarurl();
        console.log("servicioext", this.$props.servicioext);
    },
})
</script>