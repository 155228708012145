<template>
    <div class="row">
        <div class="col-md-12">
            <div v-if="servicio.danos" class="col-md-12">
                <strong>{{ $t('general.danyos') }}</strong>
                
                <p v-if="servicio.danos.length < 677" v-html="servicio.danos"></p>
                <p v-if="servicio.danos.length > 677" v-html="servicio.danos.slice(0, 678) + '...'"></p>
                <a href="#" v-if="servicio.danos.length > 677 && expandirdanos == false" @click="expandirdanos = true">Mostrar más</a>
                <p v-if="servicio.danos.length > 677 && expandirdanos == true" v-html="servicio.danos"></p>
                <a href="#" v-if="servicio.danos.length > 677 && expandirdanos == true" @click="expandirdanos=false">Mostrar menos</a>
            </div>
            <br>
            <div v-if="servicio.nota_importante" class="card bg-gradient-primary"
                style="background: #6c757d !important;">
                <div class="card-body box-profile">
                    <h4 v-if="servicio.nota_importante">Nota Importante</h4>
                    <p v-html="servicio.nota_importante"></p>
                </div>
            </div>
            <div v-if="servicio.limite_autonomia" class="col-md-12">
                <strong>Límite autonomía</strong>
                <p v-if="servicio.limite_autonomia.length < 677" v-html="servicio.limite_autonomia"></p>
                <p v-if="servicio.limite_autonomia.length > 677" v-html="servicio.limite_autonomia.slice(0, 678) + '...'"></p>
                <a href="#" v-if="servicio.limite_autonomia.length > 677 && expandirlimite == false" @click="expandirlimite = true">Mostrar más</a>
                <p v-if="servicio.limite_autonomia.length > 677 && expandirlimite == true" v-html="servicio.limite_autonomia"></p>
                <a href="#" v-if="servicio.limite_autonomia.length > 677 && expandirlimite == true" @click="expandirlimite=false">Mostrar menos</a>
            </div>
            <div v-if="observaciones" class="col-md-12">
                <strong>Observaciones</strong>
                <p v-html="observaciones"></p>
            </div>
            <div v-if="servicio.compania" class="col-md-12">
                <strong>{{ $t('general.compania') }}</strong>
                <p>{{servicio.compania.nombre}}</p>
            </div>
            <div v-if="servicio.correduria" class="col-md-12">
                <strong>{{ $t('general.companiacorreduria') }}</strong>
                <p>{{servicio.correduria.nombre}}</p>
            </div>
            <div v-if="servicio.gremio" class="col-md-6">
                <strong>{{ $t('general.gremio') }}</strong>
                <p>{{servicio.gremio}}</p>
            </div>
            <div v-if="servicio.cobertura" class="col-md-6">
                <strong>{{ $t('general.cobertura') }}</strong>
                <p>{{servicio.cobertura}}</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['id','servicio','guardartoken','notaimportante','servicioext'],
    components: {
    },
    data() {
        return {
            siniestro: [],
            observaciones: '',
            expandirdanos: false,
            expandirlimite: false,
        }
    },
    methods: {
  
    },
    mounted() {
        console.log('servdatos', this.servicio);
        
    },
    watch: {
        id() {
            this.expandirdanos = false;
            this.expandirlimite = false;
        },
        servicioext(){
            this.observaciones = this.servicioext.datos.observaciones;
        }
    }


}
</script >

