<template>
    <div class="row">
        <div class="col-md-8">
            <i class="fas fa-house-user"></i> {{ $t('general.nombre') }}
            <div class="row" style="column-gap: 5px;">
                <p class="">
                    <strong>{{asegurado.nombre}}</strong> 
                </p>
                <button style="height: 30px;" type="button" title="Notificar al asegurado para que se ponga en contacto con nosotros" class="btn btn-outline-info btn-sm" v-on:click='confirmar1()'>Notificar</button>
            </div>
        </div>
        <div class="col-md-4">
            <i class="far fa-id-card mr-1"></i> {{ $t('general.identificacion') }}
            <p class="">
                <strong>{{ asegurado.nif }}</strong>
            </p>
        </div>
    </div>
    <p></p>
    <div class="row">
        <div class="col-md-6" v-if="asegurado.telefono1 && asegurado.telefono1.length >= 9">
            <div v-if="asegurado.telefono1_descripcion==''">
                <button title="Llamar" v-on:click='llamar1()' class="btn btn-light btn-sm"><i class="fas fa-phone-alt mr-1"></i></button>
                {{ $t('general.telefono') }} 1
            </div>
            <div v-else>
                <button title="Llamar" v-on:click='llamar1()' class="btn btn-light btn-sm"><i class="fas fa-phone-alt mr-1"></i></button>
                {{ asegurado.telefono1_descripcion.slice(0,29) }}
            </div>
        </div>
        <div class="col-md-6" v-if="asegurado.telefono2 && asegurado.telefono2.length >= 9">
            <div v-if="asegurado.telefono2_descripcion==''">
                <button title="Llamar" v-on:click='llamar2()' class="btn btn-light btn-sm"><i class="fas fa-phone-alt mr-1"></i></button>
                {{ $t('general.telefono') }} 2
            </div>
            <div v-else>
                <button title="Llamar" v-on:click='llamar2()' class="btn btn-light btn-sm"><i class="fas fa-phone-alt mr-1"></i></button>
                {{ asegurado.telefono2_descripcion.slice(0,29) }}
            </div>
        </div>
    </div>
    <div class="row" @click="console.log('movil', asegurado)">
        <div class="col-md-6" v-if="asegurado.telefono1 && asegurado.telefono1.length >= 9">
            <div class="input-group input-group-sm" style="">
                <input type="text" class="form-control form-control-sm" v-if="asegurado.telefono1" v-model="telefono1" @change="cambiaTlf1">
                <div class="input-group-append">
                    <div class="btn-group" style="display: flex; flex-wrap: wrap;">
                        <button type="button" title="Enviar Sms" v-if="asegurado.telefono1" class="btn btn-outline-info btn-sm"
                            v-on:click='sms(telefono1)'><i class="fas fa-sms"></i></button>
                        <button type="button" title="Enviar Sigue Tu Expediente" v-if="servicio.ste==1"
                            class="btn btn-outline-info btn-sm" v-on:click='enviarSte("SMS",telefono1)'><i
                            class="fas fa-house-user"></i></button>
                        <button type="button" title="Enviar teleasistencia" v-if="servicio.teleasistencia==1"
                            class="btn btn-outline-info btn-sm" v-on:click='enviarTeleasistencia("SMS",telefono1)'><i
                            class="fas fa-video"></i></button>
                        <button type="button" title="Enviar WhatsApp" v-if="waActivado"
                            class="btn btn-outline-info btn-sm" @click="chat(telefono1)"><i
                            class="fab fa-whatsapp"></i></button>
                    </div>
                </div>
            </div>            
        </div>
        <div class="col-md-6" v-if="asegurado.telefono2 && asegurado.telefono2.length >= 9">
            <div class="input-group input-group-sm" style="">
                <input type="text" class="form-control form-control-sm" v-if="asegurado.telefono2" v-model="telefono2" @change="cambiaTlf2">
                <div class="input-group-append">
                    <div class="btn-group" style="display: flex; flex-wrap: wrap;">
                        <button type="button" title="Enviar Sms" v-if="asegurado.telefono2" class="btn btn-outline-info btn-sm"
                            v-on:click='sms(telefono2)'><i class="fas fa-sms"></i></button>
                        <button type="button" title="Enviar Sigue Tu Expediente" v-if="servicio.ste==1"
                            class="btn btn-outline-info btn-sm" v-on:click='enviarSte("SMS",telefono2)'><i
                            class="fas fa-house-user"></i></button>
                        <button type="button" title="Enviar teleasistencia" v-if="servicio.teleasistencia==1"
                            class="btn btn-outline-info btn-sm" v-on:click='enviarTeleasistencia("SMS",telefono2)'><i
                            class="fas fa-video"></i></button>
                        <button type="button" title="Enviar WhatsApp" v-if="waActivado"
                            class="btn btn-outline-info btn-sm" @click="chat(telefono2)"><i
                            class="fab fa-whatsapp"></i></button>
                    </div>
                </div>
            </div>            
        </div>
    </div>
    <div class="row">
        <div class="col-md-6" v-if="asegurado.telefono1 && asegurado.telefono1.length >= 9">
            <span v-if="tlfpref == 1" title="teléfono prioritario" class="badge bg-warning mr-1">Prioritario</span>
            <span v-if="asegurado.telefono1_movil == 1" title="Teléfono móvil" class="badge bg-secondary mr-1">Móvil</span>
        </div>
        <div class="col-md-6" v-if="asegurado.telefono2 && asegurado.telefono2.length >= 9">
            <span v-if="tlfpref == 2" title="teléfono prioritario" class="badge bg-warning mr-1">Prioritario</span>
            <span v-if="asegurado.telefono2_movil == 1" title="Teléfono móvil" class="badge bg-secondary mr-1">Móvil</span>
        </div>
    </div>
    <p></p>
    <div class="row">
        <div class="col-md-6" v-if="asegurado.telefono3 && asegurado.telefono3.length >= 9">
            <div v-if="asegurado.telefono3_descripcion==''">
                <button title="Llamar" v-on:click='llamar3()' class="btn btn-light btn-sm"><i class="fas fa-phone-alt mr-1"></i></button>
                {{ $t('general.telefono') }} 3
            </div>
            <div v-else>
                <button title="Llamar" v-on:click='llamar3()' class="btn btn-light btn-sm"><i class="fas fa-phone-alt mr-1"></i></button>
                {{ asegurado.telefono3_descripcion.slice(0,29) }}
            </div>
        </div>
        <div class="col-md-6" v-if="asegurado.telefono4 && asegurado.telefono4.length >= 9">
            <div v-if="asegurado.telefono4_descripcion==''">
                <button title="Llamar" v-on:click='llamar4()' class="btn btn-light btn-sm"><i class="fas fa-phone-alt mr-1"></i></button>
                {{ $t('general.telefono') }} 4
            </div>
            <div v-else>
                <button title="Llamar" v-on:click='llamar4()' class="btn btn-light btn-sm"><i class="fas fa-phone-alt mr-1"></i></button>
                {{ asegurado.telefono4_descripcion.slice(0,29) }}
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6" v-if="asegurado.telefono3 && asegurado.telefono3.length >= 9">
            <div class="input-group input-group-sm" style="">
                <input type="text" class="form-control form-control-sm" v-if="asegurado.telefono3" v-model="telefono3" @change="cambiaTlf3">
                <div class="input-group-append">
                    <div class="btn-group" style="display: flex; flex-wrap: wrap;">
                        <button type="button" title="Enviar Sms" v-if="asegurado.telefono3" class="btn btn-outline-info btn-sm"
                            v-on:click='sms(telefono3)'><i class="fas fa-sms"></i></button>
                        <button type="button" title="Enviar Sigue Tu Expediente" v-if="servicio.ste==1"
                            class="btn btn-outline-info btn-sm" v-on:click='enviarSte("SMS",telefono3)'><i
                            class="fas fa-house-user"></i></button>
                        <button type="button" title="Enviar teleasistencia" v-if="servicio.teleasistencia==1"
                            class="btn btn-outline-info btn-sm" v-on:click='enviarTeleasistencia("SMS",telefono3)'><i
                            class="fas fa-video"></i></button>
                        <button type="button" title="Enviar WhatsApp" v-if="waActivado"
                            class="btn btn-outline-info btn-sm" @click="chat(telefono3)"><i
                            class="fab fa-whatsapp"></i></button>
                    </div>
                </div>
            </div>            
        </div>
        <div class="col-md-6" v-if="asegurado.telefono4 && asegurado.telefono4.length >= 9">
            <div class="input-group input-group-sm" style="">
                <input type="text" class="form-control form-control-sm" v-if="asegurado.telefono4" v-model="telefono4" @change="cambiaTlf4">
                <div class="input-group-append">
                    <div class="btn-group" style="display: flex; flex-wrap: wrap;">
                        <button type="button" title="Enviar Sms" v-if="asegurado.telefono4" class="btn btn-outline-info btn-sm"
                            v-on:click='sms(telefono4)'><i class="fas fa-sms"></i></button>
                        <button type="button" title="Enviar Sigue Tu Expediente" v-if="servicio.ste==1"
                            class="btn btn-outline-info btn-sm" v-on:click='enviarSte("SMS",telefono4)'><i
                            class="fas fa-house-user"></i></button>
                        <button type="button" title="Enviar teleasistencia" v-if="servicio.teleasistencia==1"
                            class="btn btn-outline-info btn-sm" v-on:click='enviarTeleasistencia("SMS",telefono4)'><i
                            class="fas fa-video"></i></button>
                    </div>
                </div>
            </div>            
        </div>
    </div>
    <div class="row">
        <div class="col-md-6" v-if="asegurado.telefono3 && asegurado.telefono3.length >= 9">
            <span v-if="tlfpref == 3" title="Teléfono prioritario" class="badge bg-warning mr-1">Prioritario</span>
            <span v-if="asegurado.telefono3_movil == 1" title="Teléfono móvil" class="badge bg-secondary mr-1">Móvil</span>
        </div>
        <div class="col-md-6" v-if="asegurado.telefono4 && asegurado.telefono4.length >= 9">
            <span v-if="tlfpref == 4" title="Teléfono prioritario" class="badge bg-warning mr-1">Prioritario</span>
        </div>
    </div>
    <p></p>
    <div class="row" v-if="mostrarComunicacionSMS">
        <div class="col-md-12">
            <ComunicacionSMS :id="id" :numero="numero_envio" :asegurado="asegurado" :servicio="servicio" />
        </div>
    </div>
    <p></p>
    <div class="row">
        <div class="col-md-12 " v-if="asegurado.email">
            <i class="fas fa-at mr-1"></i>Email
        </div>
        <div class="col-md-12 " v-if="asegurado.email">
            <div class="btn-group" v-if="asegurado.email">
                <button type="button" title="Enviar E-mail" v-on:click='email(asegurado.email)'
                    class="btn btn-block btn-outline-info btn-sm">{{ asegurado.email }}</button>
                <button type="button" title="Enviar Sigue Tu Expediente por email" v-if="servicio.ste==1"
                    class="btn btn-outline-info btn-sm" v-on:click='enviarSte("EMAIL",asegurado.email)'><i
                    class="fas fa-house-user"></i></button>
                <button type="button" title="Enviar teleasistencia por email" v-if="servicio.teleasistencia==1"
                    class="btn btn-outline-info btn-sm" v-on:click='enviarTeleasistencia("EMAIL",asegurado.email)'><i
                    class="fas fa-video"></i></button>
            </div>
        </div>
    </div>
    <p></p>
    <div class="row" v-if="mostrarComunicacionEmail">
        <div class="col-md-12">
            <ComunicacionEmail :id="id" :email="asegurado.email" :asegurado="asegurado" :servicio="servicio" />
        </div>
    </div>
    <p></p>
    <div class="row" v-if="asegurado.poliza">
        <div class="col-md-12">
            <i class="fas fa-file-contract mr-1"></i>{{ $t('general.poliza') }}
            <p class="">
                <strong>{{ asegurado.poliza }}</strong>
            </p>
        </div>
    </div>
    <div class="row" v-if="asegurado.observaciones">
        <div class="col-md-12">
            <i class="fas fa-info-circle mr-1"></i>{{ $t('general.observaciones') }}
            <p class="">
                <strong>{{ asegurado.observaciones }}</strong>
            </p>
        </div>
    </div>
</template>
<script>  
import { PwgsApi } from '../../../../../services/PwgsApi';
import ComunicacionEmail from '../../../comunicaciones/ComunicacionEmail.vue'
import ComunicacionSMS from '../../../comunicaciones/ComunicacionSMS.vue'
export default ({
    props: ['id', 'asegurado', 'servicio','servicioext'],
    setup() { 

    },
    data() {
        return {
            waActivado:false,
            tlfpref:0,
            mostrarComunicacionEmail: false,
            email_envio: '',
            mostrarComunicacionSMS: false,
            numero_envio: '',
            telefono1:'',
            telefono2:'',
            telefono3:'',
            telefono4:'',
            telefono1nuevo:null,
            telefono2nuevo:null,
            telefono3nuevo:null,
            telefono4nuevo:null,
            urlapu : localStorage.getItem('direccion'),
        }
    },
    components: {
        ComunicacionEmail,
        ComunicacionSMS
    },
    methods: {
        async cambiaTlf1(){
            if(this.telefono1.length < 9){
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: 'El teléfono debe contener al menos 9 dígitos', life: 5000 });
                if(this.telefono1nuevo){
                    this.telefono1 = this.telefono1nuevo;
                }else{
                    this.telefono1 = this.asegurado.telefono1;
                }
                return;
            }
            const soloNumeros = /^[0-9]+$/;
            if(soloNumeros.test(this.telefono1)){
                const api = new PwgsApi();
                try{
                    await api.put('clientes/' + this.asegurado.idcliente + '/modificar-cliente-servicio/' +this.$props.id, {telefono1:this.telefono1});
                    this.$toast.add({ severity:'success', summary: 'PiExito', detail: 'El teléfono 1 se ha modificado correctamente', life: 5000 });
                    this.telefono1nuevo = this.telefono1;
                }catch(e) {
                    this.$toast.add({ severity: 'error', summary: 'PiError', detail: e, life: 5000 });
                    if(this.telefono1nuevo){
                        this.telefono1 = this.telefono1nuevo;
                    }else{
                        this.telefono1 = this.asegurado.telefono1;
                    }
                }
            }else{
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: 'El teléfono solo puede contener números', life: 5000 });
                if(this.telefono1nuevo){
                    this.telefono1 = this.telefono1nuevo;
                }else{
                    this.telefono1 = this.asegurado.telefono1;
                }
                return;
            }
        },
        async cambiaTlf2(){
            if(this.telefono2.length < 9){
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: 'El teléfono debe contener al menos 9 dígitos', life: 5000 });
                if(this.telefono2nuevo){
                    this.telefono2 = this.telefono2nuevo;
                }else{
                    this.telefono2 = this.asegurado.telefono2;
                }
                return;
            }
            const soloNumeros = /^[0-9]+$/;
            if(soloNumeros.test(this.telefono2)){
                const api = new PwgsApi();
                try{
                    await api.put('clientes/' + this.asegurado.idcliente + '/modificar-cliente-servicio/' +this.$props.id, {telefono2:this.telefono2});
                    this.$toast.add({ severity:'success', summary: 'PiExito', detail: 'El teléfono 2 se ha modificado correctamente', life: 5000 });
                    this.telefono2nuevo = this.telefono2;
                }catch(e) {
                    this.$toast.add({ severity: 'error', summary: 'PiError', detail: e, life: 5000 });
                    if(this.telefono2nuevo){
                        this.telefono2 = this.telefono2nuevo;
                    }else{
                        this.telefono2 = this.asegurado.telefono2;
                    }
                }
            }else{
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: 'El teléfono solo puede contener números', life: 5000 });
                if(this.telefono2nuevo){
                    this.telefono2 = this.telefono2nuevo;
                }else{
                    this.telefono2 = this.asegurado.telefono2;
                }
                return;
            }
        },
        async cambiaTlf3(){
            if(this.telefono3.length < 9){
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: 'El teléfono debe contener al menos 9 dígitos', life: 5000 });
                if(this.telefono3nuevo){
                    this.telefono3 = this.telefono3nuevo;
                }else{
                    this.telefono3 = this.asegurado.telefono3;
                }
                return;
            }
            const soloNumeros = /^[0-9]+$/;
            if(soloNumeros.test(this.telefono3)){
                const api = new PwgsApi();
                try{
                    await api.put('clientes/' + this.asegurado.idcliente + '/modificar-cliente-servicio/' +this.$props.id, {telefono3:this.telefono3});
                    this.$toast.add({ severity:'success', summary: 'PiExito', detail: 'El teléfono 3 se ha modificado correctamente', life: 5000 });
                    this.telefono3nuevo = this.telefono3;
                }catch(e) {
                    this.$toast.add({ severity: 'error', summary: 'PiError', detail: e, life: 5000 });
                    if(this.telefono3nuevo){
                        this.telefono3 = this.telefono3nuevo;
                    }else{
                        this.telefono3 = this.asegurado.telefono3;
                    }
                }
            }else{
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: 'El teléfono solo puede contener números', life: 5000 });
                if(this.telefono3nuevo){
                    this.telefono3 = this.telefono3nuevo;
                }else{
                    this.telefono3 = this.asegurado.telefono3;
                }
                return;
            }
        },
        async cambiaTlf4(){
            if(this.telefono4.length < 9){
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: 'El teléfono debe contener al menos 9 dígitos', life: 5000 });
                if(this.telefono4nuevo){
                    this.telefono4 = this.telefono4nuevo;
                }else{
                    this.telefono4 = this.asegurado.telefono4;
                }
                return;
            }
            const soloNumeros = /^[0-9]+$/;
            if(soloNumeros.test(this.telefono4)){
                const api = new PwgsApi();
                try{
                    await api.put('clientes/' + this.asegurado.idcliente + '/modificar-cliente-servicio/' +this.$props.id, {telefono4:this.telefono4});
                    this.$toast.add({ severity:'success', summary: 'PiExito', detail: 'El teléfono 4 se ha modificado correctamente', life: 5000 });
                    this.telefono4nuevo = this.telefono4;
                }catch(e) {
                    this.$toast.add({ severity: 'error', summary: 'PiError', detail: e, life: 5000 });
                    if(this.telefono4nuevo){
                        this.telefono4 = this.telefono4nuevo;
                    }else{
                        this.telefono4 = this.asegurado.telefono4;
                    }
                }
            }else{
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: 'El teléfono solo puede contener números', life: 5000 });
                if(this.telefono4nuevo){
                    this.telefono4 = this.telefono4nuevo;
                }else{
                    this.telefono4 = this.asegurado.telefono4;
                }
                return;
            }
        },
        async notificar(){
            console.log('notifica');        
            const api = new PwgsApi();
            let subidadatos = {id_cliente : this.$props.servicio.asegurado.id};
            const resp = await api.put('servicios/'+this.id+'/notificar-cliente',subidadatos);
            console.log('notificado',resp);
        },
        confirmar1() {
            this.$confirm.require({
                message: '¿Seguro que quieres notificar al asegurado para que se ponga en contacto con nosotros?',
                header: 'Confirmación',
                icon: 'pi pi-exclamation-triangle',
                rejectClass: 'p-button-secondary p-button-outlined',
                rejectLabel: 'Cancelar',
                acceptLabel: 'Aceptar',
                accept: () => {
                    this.notificar();
                },
                reject: () => {
                    return;
                }
            });
        },
        comprobarPref(){
            if(this.asegurado.telefono_prioritario==1){
                this.tlfpref = '1';
            }else if(this.asegurado.telefono_prioritario==2){
                this.tlfpref = '2';
            }else if(this.asegurado.telefono_prioritario==3){
                this.tlfpref = '3';
            }else if(this.asegurado.telefono_prioritario==4){
                this.tlfpref = '4';
            }
            if(this.asegurado.telefono1){
                this.telefono1 = this.asegurado.telefono1;
            }
            if(this.asegurado.telefono2){
                this.telefono2 = this.asegurado.telefono2;
            }
            if(this.asegurado.telefono3){
                this.telefono3 = this.asegurado.telefono3;
            }
            if(this.asegurado.telefon4){
                this.telefono4 = this.asegurado.telefono4;
            }
        },
       llamar1 : function(){
            if(this.telefono1nuevo){
                window.open("tel:"+this.telefono1nuevo);
            }else{
                window.open("tel:"+this.asegurado.telefono1);
            }
       },
       llamar2 : function(){        
            if(this.telefono2nuevo){
                window.open("tel:"+this.telefono2nuevo);
            } else{
                window.open("tel:"+this.asegurado.telefono2);
            }
       },
       llamar3 : function(){
            if(this.telefono3nuevo){
                window.open("tel:"+this.telefono3nuevo);
            } else{
                window.open("tel:"+this.asegurado.telefono3);
            }
       },
       llamar4 : function(){
            if(this.telefono4nuevo){
                window.open("tel:"+this.telefono4nuevo);
            } else{
                window.open("tel:"+this.asegurado.telefono4);
            }
       },
       email : function(email){

            if(this.mostrarComunicacionEmail === true){
                this.mostrarComunicacionEmail = false;
            }else{
                this.mostrarComunicacionEmail = true;
            }
            this.email_envio = email;
            
        },
        async enviarTeleasistencia(tipo,valor) {
            //PUT /servicios/:id/enviar-ste/:id_cliente
            const api = new PwgsApi();
            let subidadatos = {tipo:tipo,teleasistencia:1}
            if (tipo == "SMS") {
                subidadatos.telefono = valor;
            }
            try {
                await api.put('servicios/' + this.$props.id + '/enviar-ste/' + this.$props.servicio.asegurado.id, subidadatos);
                this.$toast.add({ severity: 'success', summary: 'Enviado', detail: 'Ste enviado correctamente', life: 2000 });

            }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
         },
       async enviarSte(tipo,valor) {
            //PUT /servicios/:id/enviar-ste/:id_cliente
            const api = new PwgsApi();
            let subidadatos = { tipo: tipo }
            if (tipo == "SMS") {
                subidadatos.telefono = valor;
            }
            try{
                await api.put('servicios/' + this.$props.id + '/enviar-ste/' + this.$props.servicio.asegurado.id, subidadatos);
                this.$toast.add({ severity: 'success', summary: 'Enviado', detail: 'Ste enviado correctamente', life: 2000 });

            }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
       },
       sms : function(numero){

            if(this.mostrarComunicacionSMS === true){
                this.mostrarComunicacionSMS = false;
            }else{
                this.mostrarComunicacionSMS = true;
            }
            this.numero_envio = numero;
            
       },
       async comprobarwa () {
            const api = new PwgsApi();
            var wa = await api.get('parametros-pwgs/whatsapp');
            if(wa.whatsapp=='1'){
                this.waActivado = true;
            }else{
                this.waActivado = false;
            }
        },
        chat(tlf){
            var url  = "https://"+this.urlapu+"/modulos/chat/panel.php?vista=vista_chats&id_cliente="+this.$props.servicio.asegurado.id+"&id_visita=0&id_servicio="+this.$props.id+"&telefono="+tlf
            window.open(url, "Chat", "width=800, height=600");
        }
    },
    mounted(){
        this.comprobarPref();
        this.comprobarwa();
    },
    watch:{
        asegurado(){
            this.comprobarPref();
            console.log('asegurado', this.asegurado);
        }
    },
})


</script>