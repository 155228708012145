<template>
    <!-- Poner la dirección del asegurado -->
    <div class="card">
        <div class="card-header bg-gray cabecera-direccion">
            <h3 class="card-title">
                <i class="fas fa-map mr-2"></i>
                {{ $t('general.direccion') }}
            </h3>
            <div class="card-tools">
                <button type="button" class="btn btn-success btn-xs editar-direccion" id="boton_guardar_servicio" >{{ $t('general.guardar') }} </button>
                <button type="button" class="btn btn-tool" title="Pantalla completa" @click="ampliarmapa= !ampliarmapa" data-card-widget="maximize">
                    <i class="fas fa-expand"></i>
                </button>
                <button type="button" class="btn btn-tool ver-direccion" title="Añadir nuevas direcciones" v-on:click="editar">
                    <i class="fas fa-edit"></i>
                </button>
                <button type="button" class="btn btn-tool editar-direccion" title="Editar" v-on:click="ver">
                    <i class="fas fa-eye"></i>
                </button>                
               <!-- <button type="button" class="btn btn-tool ampliar-direccion" title="Ampliar" v-on:click="ampliar">
                    <i class="fas fa-plus"></i>
                </button>-->
                <button type="button" class="btn btn-tool reducir-direccion" title="Reducir" v-on:click="ver">
                    <i class="fas fa-minus"></i>
                </button>
            </div>
        </div><!-- /.card-header -->
        <div class="card-body">
           <!-- <keep-alive>-->
                <component v-bind:is="component" :cliente="datoscliente" :ampliarmapa="ampliarmapa"  :perjudicados="perjudicados" :asegurado="asegurado" :servicio="servicio" :id="id"/>            
          <!--  </keep-alive>-->
        </div>
    </div>
</template>
<script>  
import serviciodatosdireccionsimple from './direccion_simple.vue';
import serviciodatosdirecciondetallado from './direccion_detallado.vue';
import serviciodatosdireccionform from './direccion_form.vue';
import $ from 'jquery'
import { PwgsApi } from '../../../../../services/PwgsApi';
export default ({
    props: ['id', 'servicio','perjudicados','asegurado'],
    setup() {
        return;
    },mounted() {
        this.ver();
        this.cargardatos(this.$props.id);        
    },
    watch:{
        id(){
            this.cargardatos(this.$props.id);
        },
        $route() {
            this.ver();
        }
    },
    components: {
        'servicio-datos-direccion-simple': serviciodatosdireccionsimple,
        'servicio-datos-direccion-detallado': serviciodatosdirecciondetallado,
        'servicio-datos-direccion-form': serviciodatosdireccionform,
    },
    data (){
        return {
            component: "servicio-datos-direccion-simple",
            datoscliente: [],
            cliente: [],
            ampliarmapa:false,
        }
    },
    methods: {
        editar(){
            this.component = "servicio-datos-direccion-form";
            $(".editar-direccion").show();
            $(".ver-direccion").hide();
            $(".cabecera-direccion").removeClass("bg-gray");
            $(".cabecera-direccion").addClass("bg-black");
        },
        ver(){
            this.component = "servicio-datos-direccion-simple";
            $(".editar-direccion").hide();
            $(".reducir-direccion").hide();
            $(".ampliar-direccion").show();
            $(".ver-direccion").show();
            $(".cabecera-direccion").removeClass("bg-black");
            $(".cabecera-direccion").addClass("bg-gray");
        },
        ampliar(){
            this.component = "servicio-datos-direccion-detallado";
            $(".ampliar-direccion").hide();
            $(".reducir-direccion").show();
            $(".cabecera-direccion").removeClass("bg-black");
            $(".cabecera-direccion").addClass("bg-gray");
        },
        async cargardatos(id) {
            // modulos/pwgsapi/index.php/servicios/:id/clientes
            console.log('entraaaw');
            const api = new PwgsApi
            const datos = await api.get("servicios/" + id + '/clientes');
            this.datoscliente = datos;
            
        },
    },
    
})


</script>